<template>
  <div>
    <Nav />
    <br /><br /><br /><br />
    <Title type="fast-her" class="parent">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h1 class="text-start">
              Empowering Girls in Rural Africa with Digital Literacy Skills -
              <small style="color: rgb(244, 239, 239)"
                >Join Us Today to Make a Difference!</small
              >
            </h1>
          </div>
        </div>
        <div class="bee-block bee-block-5 bee-button mt-3">
          <a class="bee-button-content myButton" 
            ><span
              dir="ltr"
              @click="scrollToDonate"
              style="word-break: break-word;  font-size: 16px; line-height: 200%"
              >Get Started</span
            ></a
          >
        </div>
      </div>
    </Title>
    <div class="container-fluid">
      <section class="parallax-container">
        <div class="bee-row bee-row-6">
          <div class="bee-row-content reverse spaces">
            <div class="bee-col bee-col-1 bee-col-w6">
              <!-- <div class="bee-block bee-block-1 bee-image"> -->
              <img
                alt="Students 2"
                class="bee-center bee-autowidth rounded"
                src="https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690995767/e7wkvph1flckioystiz7.jpg"
                style="width: 100%; height: 100%"
              />
              <!-- </div> -->
            </div>
            <div class="bee-col bee-col-2 bee-col-w6">
              <div class="bee-block bee-block-1 bee-heading">
                <h2
                  style="
                    color: #e07f4f;
                    direction: ltr;
                    font-family: Nunito, Arial, Helvetica Neue, Helvetica,
                      sans-serif;
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    line-height: 120%;
                    text-align: left;
                    margin-top: 0;
                    margin-bottom: 0;
                  "
                ></h2>
              </div>
              <div class="bee-block bee-block-2 bee-heading">
                <h2>
                  <span class="tinyMce-placeholder"
                    >Empower Africa's Future: Support Our Digital Literacy Courses for Girls in Rural Areas</span
                  >
                </h2>
              </div>
              <div class="bee-block bee-block-3 bee-paragraph">
                <p>
                  Welcome to our digital literacy online courses designed specifically for girls in rural 
                  areas of Africa! We are thrilled to accompany you on an empowering journey of acquiring 
                  essential digital skills for success in the 21st century.
                </p>
              </div>
              <div
                class="bee-block ml-3 text-capitalize bee-block-4 bee-list"
                style="margin-left: 5px"
              >
                <ul start="1" style="list-style-image: ">
                  <li>
                    <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                    <strong style="margin-left: 5px">Bridging the Digital Divide</strong><br />
                    Empowering Girls in Rural Africa with Essential Digital Skills.
                  </li>
                  <br />
                  <li>
                    <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                    <strong style="margin-left: 5px">Your Support Matters</strong><br />
                    Partner with Us to Equip Girls in Rural Africa with the Tools for 21st Century Success.
                  </li>
                  <br />
                  <li>
                    <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                    <strong style="margin-left: 5px">Partner with Us to Equip Girls in Rural Africa with the Tools for 21st Century Success.</strong
                    ><br />
                    Together, Let's Provide Digital Literacy Skills for a Brighter Future.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="bee-row bee-row-6">
        <div class="bee-row-content reverse spaces">
          <br /><br />
          <div class="bee-col bee-col-2 bee-col-w6">
            <div class="bee-block bee-block-1 bee-heading">
              <h2></h2>
            </div>
            <div class="bee-block bee-block-2 bee-heading">
              <h2>
                <span class="tinyMce-placeholder">The Fast-Her Process Unveiled:</span>
              </h2>
            </div>
            <div class="bee-block bee-block-3 bee-paragraph">
              <p>
                Once payments are completed, Faslearn promptly generates student memberships for
                all individuals you've sponsored. An email containing a link will be sent to you, 
                allowing you to track the study progress of your students.
              </p>
            </div>
            <div class="bee-block text-capitalize bee-block-4 bee-list">
              <h3>Empowering Process:</h3>
              <ul start="1">
                <br />
                <li>
                  <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                  Step 1: Select the Number of Students You Wish to Support
                </li>
                <br />
                <li>
                  <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                  Step 2: Make Secure Payments to Proceed
                </li>
                <br />
                <li>
                  <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                  Step 3: Faslearn Admins Create Your Membership and Enroll Girls from Rural Areas
                </li>
                <br />
                <li>
                  <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                  Step 4: Optionally, Add Additional Participants by Creating Accounts for Each Individual
                </li>
                <br />
                <li>
                  <i class="ti-hand-point-right" style="color: #e07f4f"></i>
                  Step 5: Within 4 Working Days, You Will Receive an Email with a Membership Link to Monitor the Study Progress of the Sponsored Students
                </li>
              </ul>
            </div>
          </div>
          <div class="bee-col bee-col-2 bee-col-w6">
            <div class="bee-block bee-block-1 bee-heading">
              <h2>
                <!-- <span class="tinyMce-placeholder"
                      ><strong>→</strong>UNTIL 10.06.2022</span
                    > -->
              </h2>
            </div>
            <div class="bee-block bee-block-2 bee-heading" id="donateNow">
              <h2>
                <span class="tinyMce-placeholder">Donate Now</span>
              </h2>
            </div>
            <form class="donateForm">
              <div class="form-group">
                <span class="input">
                  <input
                    class="input_field"
                    type="text"
                    autocomplete="off"
                    name="text"
                    v-model="firstName"
                  />
                  <label class="input_label">
                    Please enter your first name
                  </label>
                </span>
                <span class="input">
                  <input
                    class="input_field"
                    type="text"
                    autocomplete="off"
                    name="text"
                    v-model="lastName"
                  />
                  <label class="input_label">
                    Please enter your last name
                  </label>
                </span>
                <span class="input">
                  <input
                    class="input_field"
                    type="text"
                    autocomplete="off"
                    name="text"
                    v-model="email"
                  />
                  <label class="input_label"> Please enter your email </label>
                </span>
                <span class="input">
                  <input
                    class="input_field"
                    type="text"
                    autocomplete="off"
                    name="text"
                    v-model="phone"
                  />
                  <label class="input_label">
                    Please enter your mobile number
                  </label>
                </span>
                <span class="input">
                  <input
                    class="input_field"
                    type="number"
                    autocomplete="off"
                    @keyup="price = totalStudents * single_price"
                    name="number"
                    v-model="totalStudents"
                  />
                  <label class="input_label">
                    How many girls are you donating for
                  </label>
                </span>

                <span class="input">
                  <input
                    class="input_field"
                    type="number"
                    name="amount"
                    v-model="price"
                    disabled
                  />
                  <label class="input_label"> Amount </label>
                </span>

                <span>
                  <Errors v-if="errors.length != 0">
                    <ul>
                      <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                  </Errors>
                </span>
              </div>
              <div class="" @click.prevent="submitDonation()">
                <a class="bee-button-content myButton"
                  ><span
                    dir="ltr"
                    style="
                      word-break: break-word;
                      font-size: 16px;
                      line-height: 200%;
                      cursor: pointer;
                      color: white;
                    "
                    >Donate ₦{{ price }} Now</span
                  ></a
                >
              </div>
            </form>
            <div class="divider"><span>Or</span></div>
            
          </div>
        </div>
      </div>
  

      <div class="bee-row bee-row-7">
        <div class="bee-row-content">
          <div class="bee-col bee-col-1 bee-col-w12">
            <div class="bee-block bee-block-1 bee-spacer">
              <div class="spacer" style="height: 35px"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg_color_1">
        <SkeletonLoader v-if="initLoading" />
        <div class="container margin_60_35" v-else>
          <div class="row">
            <aside class="col-lg-4" id="sidebar">
              <div class="box_detail">
                <figure>
                  <a
                    class="video"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    ><i class="arrow_triangle-right"></i
                    ><CourseImage
                      :name="details.thumbnailFile"
                      style="height: 200px; width: 100%"
                    /><span>View course preview</span
                    ><!--<img v-bind:src="backEnd+'public/courseImage/'+courseId" alt="" class="img-fluid">--><!--<span>View course preview</span>--></a
                  >
                </figure>
                <div class="price" v-if="single_price != 0">
                  <span v-if="rate">
                    <span v-if="selectedCurrency === 'GBP'">
                      £{{
                        (single_price * parseFloat(rate))
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </span>
                    <span v-if="selectedCurrency === 'NGN'">
                      ₦{{
                        (single_price * parseFloat(rate))
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </span>
                    <span v-if="selectedCurrency === 'USD'">
                      ${{
                        (single_price * parseFloat(rate))
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </span>
                    <span class="original_price">
                      <br />
                      <Discount
                        :rate="rate"
                        :selectedCurrency="selectedCurrency"
                        :id="details._id"
                        :price="single_price"
                        :discount="details.discountPercent"
                        v-if="details != ''"
                      />
                    </span>
                  </span>
                  <span v-else>
                    ₦{{
                      single_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}<span class="original_price"
                      ><br /><Discount
                        :id="details._id"
                        :price="single_price"
                        :discount="details.discountPercent"
                        v-if="details != ''"
                      />
                    </span>
                  </span>
                </div>
                <div class="price" v-else>FREE</div>
                <a
                  @click="scrollToDonate"
                  class="btn_1 full-width"
                  style="color: white; background-color: red"
                  >Donate</a
                >
                <a @click="scrollToDonate" class="btn_1 full-width outline"
                  ><i class="icon_heart"></i>Add to wishlist</a
                >
                <div id="list_feat">
                  <h3>What's includes</h3>
                  <ul>
                    <li><i class="icon_archive_alt"></i>Lesson archive</li>
                    <li><i class="icon_mobile"></i>Mobile support</li>
                    <li><i class="icon_chat_alt"></i>Tutor chat</li>
                    <li><i class="icon_document_alt"></i>Course certificate</li>
                    <li><i class="icon_lock-open_alt"></i>Live access</li>
                    <li><i class="icon_clock_alt"></i>Self pace</li>
                  </ul>
                  <br />
                  <hr />
                  <div v-if="details != ''">
                    <h5>Course by :</h5>
                    <p>
                      {{ details.user[0].firstName }}
                      {{ details.user[0].LastName }}
                    </p>
                  </div>
                </div>
              </div>
            </aside>
            <div class="col-lg-8">
              <section id="description">
                <h2>Course Description</h2>
                <p
                  v-if="details != ''"
                  v-html="sanitizeDescription(details.description)"
                ></p>
                <h5>You will learn :</h5>
                <ul>
                  <li v-for="what in whatYouLearn" :key="what">
                    <h6><i class="icon-check"></i> {{ what }}</h6>
                  </li>
                </ul>
                <hr />
                <h2>Requirements</h2>
                <div class="row">
                  <div class="col-lg-6">
                    <ul>
                      <li>
                        <i class="icon_target"></i> {{ details.requirement }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /row -->
              </section>

              <section id="lessons">
                <div class="intro_title">
                  <h2>Lessons</h2>
                  <ul>
                    <li>{{ lectures.length }} lessons</li>
                  </ul>
                </div>
                <div
                  id="accordion_lessons"
                  role="tablist"
                  class="add_bottom_45"
                >
                  <div class="card" v-for="lect in lectures" :key="lect">
                    <div class="card-header" role="tab" id="headingOne">
                      <h5 class="mb-0">
                        <a
                          data-toggle="collapse"
                          href="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          ><i class="icon_folder-open"></i>
                          {{ lect.sectionTitle }}</a
                        >

                        <small
                          v-if="lect.description != null"
                          v-html="sanitizeDescription(lect.description)"
                        ></small>
                        <br />
                        <small
                          v-if="lect.externalLink != null"
                          v-html="sanitizeDescription(lect.externalLink)"
                        ></small>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                      data-parent="#accordion_lessons"
                    >
                      <div class="card-body">
                        <div class="list_lessons">
                          <ul>
                            <li v-for="play in lect.videos" :key="play">
                              <a v-if="play.fileType == 'application/pdf'"
                                ><i class="icon_document"></i>
                                {{ play.title }}</a
                              >
                              <a
                                v-if="
                                  play.fileType != 'application/pdf' &&
                                  lect.sectionTitle != 'Preview'
                                "
                                ><i class="arrow_triangle-right_alt"></i>
                                {{ play.title }}
                              </a>
                              <a
                                v-else
                                v-bind:data-toggle="
                                  lect.sectionTitle == 'Preview'
                                    ? 'modal'
                                    : 'false'
                                "
                                @click="
                                  switchFile(
                                    play.uri,
                                    play.embedLink,
                                    play.fileType
                                  )
                                "
                                data-target="#exampleModalCenter"
                                style="cursor: pointer"
                                ><i class="arrow_triangle-right_alt"></i>
                                {{ play.title }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /card -->
                </div>
                <!-- /accordion -->
              </section>
              <!-- /section -->

              <RatingsDetails :id="course" :type="'course'" />
            </div>

            
          </div>
        </div>
      </div>

      <div class="bee-row bee-row-7">
        <div class="bee-row-content">
          <div class="bee-col bee-col-1 bee-col-w12">
            <div class="bee-block bee-block-1 bee-spacer">
              <div class="spacer" style="height: 35px"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="bee-row bee-row-7">
        <div class="bee-row-content">
          <div class="bee-col bee-col-1 bee-col-w12">
            <div class="bee-block bee-block-1 bee-spacer">
              <div class="spacer" style="height: 35px"></div>
            </div>
          </div>
        </div>
      </div>
      <section class="parallax-container parallax-container2">
        <div class="bee-row bee-row-11">
          <div class="bee-row-content spaces">
            <div class="bee-col bee-col-1 bee-col-w6">
              <div class="bee-block bee-block-1 bee-heading">
                <h2>
                  <!-- <span class="tinyMce-placeholder"
                      ><strong>→</strong>ACCESS NOW!</span
                    > -->
                </h2>
              </div>
              <div class="bee-block bee-block-2 bee-heading">
                <h2>About the Program</h2>
                <small>(FastHer Digital Literacy Initiative by Faslearn)</small>
              </div>
              <div class="bee-block bee-block-3 bee-paragraph">
                <p>Faslearn presents online courses tailored specifically for girls in rural areas of Africa.</p>
                <p>
                  In today's fast-paced world, digital literacy is crucial for success across all domains. 
                  Unfortunately, access to digital education remains limited in many regions worldwide, 
                  including rural areas in Africa. These challenges are especially pronounced for girls, 
                  who encounter additional obstacles in accessing education and acquiring essential digital literacy skills.
                </p>
              </div>

              <div class="bee-block bee-block-7 bee-button">
                <router-link
                  class="bee-button-content"
                  to="/fast-her/about"
                  style="
                    font-size: 16px;
                    background-color: #e07f4f;
                    border-bottom: 0px solid #8a3b8f;
                    border-left: 0px solid #8a3b8f;
                    border-radius: 50px;
                    border-right: 0px solid #8a3b8f;
                    border-top: 0px solid #8a3b8f;
                    color: #ffffff;
                    font-family: 'Nunito', Arial, 'Helvetica Neue', Helvetica,
                      sans-serif;
                    font-weight: 400;
                    max-width: 100%;
                    padding-bottom: 10px;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-top: 10px;
                    width: auto;
                    direction: ltr;
                    display: inline-block;
                  "
                  ><span
                    dir="ltr"
                    style="
                      word-break: break-word;
                      font-size: 16px;
                      line-height: 200%;
                    "
                    >Learn More</span
                  ></router-link
                >
              </div>
            </div>
            <div class="bee-col bee-col-2 bee-col-w6">
              <div class="bee-block bee-block-1 bee-image rounded">
                <img
                  alt="Students 2"
                  class="bee-center bee-autowidth rounded img-thumbnail"
                  src="https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690995767/t8gb5eyeskxjzmdeo90r.jpg"
                  style="max-width: 586px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="bee-row bee-row-7">
        <div class="bee-row-content">
          <div class="bee-col bee-col-1 bee-col-w12">
            <div class="bee-block bee-block-1 bee-spacer">
              <div class="spacer" style="height: 35px"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bee-row bee-row-11">
        <div class="bee-row-content spaces px-5">
          <div class="bee-col bee-col-1 bee-col-w6">
            <div class="bee-block bee-heading">
              <h2>
                <span class="tinyMce-placeholder">
                  Become a Catalyst for Change: Support Fast-Her's Mission of Empowering African Women through Digital Literacy. 
                  Join Us and Make a Difference by Donating Today.</span
                >
              </h2>
            </div>
          </div>
          <div class="bee-col bee-col-2 bee-col-w6 text-center">
            <div class="bee-block bee-block-7 bee-button">
              <a
                class="bee-button-content text-center"
                @click="scrollToDonate"
                style="
                  width: 200px;
                  font-size: 16px;
                  background-color: #e07f4f;
                  border-bottom: 0px solid #8a3b8f;
                  border-left: 0px solid #8a3b8f;
                  border-radius: 50px;
                  border-right: 0px solid #8a3b8f;
                  border-top: 0px solid #8a3b8f;
                  color: #ffffff;
                  font-family: 'Nunito', Arial, 'Helvetica Neue', Helvetica,
                    sans-serif;
                  font-weight: 400;
                  max-width: 100%;
                  padding-bottom: 10px;
                  padding-left: 25px;
                  padding-right: 25px;
                  padding-top: 10px;
                  direction: ltr;
                  display: inline-block;
                "
                ><span
                  dir="ltr"
                  style="
                    word-break: break-word;
                    font-size: 16px;
                    line-height: 200%;
                  "
                  >Donate Now</span
                ></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bee-row bee-row-7">
        <div class="bee-row-content">
          <div class="bee-col bee-col-1 bee-col-w12">
            <div class="bee-block bee-block-1 bee-spacer">
              <div class="spacer" style="height: 35px"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container margin_60_35">
        <div class="row mb-5 text-center justify-content-center">
          <h1>Our Current Events</h1>
        </div>
        <div class="row">
          
            <article class="blog wow fadeIn col-md-4 col-sm-12" v-for="p in posts" :key="p">
              <div class="row no-gutters">
                <div class="col-lg-12" >
                  <figure>
                    <router-link :to="'/blogpost/' + p._id">
                      <a href="#">
                        <img
                          :src="publicFiles + p.imageFile"
                          v-if="p.imageFile"
                          alt=""
                          class="fluid"
                        />
                        <img
                          class="img-fluid"
                          src="../../assets/fast_2.png"
                          v-else
                          alt=""
                        />
                        <div class="preview"><span>Read more</span></div>
                      </a>
                    </router-link>
                  </figure>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  
                    <p @click="$router.push(`/blogpost/${p._id}`)" style="color:#E07F4F" >
                    {{ p.title }}
                    </p >
                  
                    <ul>
                      <li>
                        <div class="thumb"><img src="" alt="" /></div>
                        {{ p.superAdmin[0].name }} <small>{{ p.createdAt.split("T")[0] }}</small>
                      </li>
                    </ul>
                
                </div>
              </div>
            </article>
            <!-- /article -->

            
            <!-- /pagination -->
          <!-- /col -->
        </div>
        <nav aria-label="...">
              <ul class="pagination pagination-sm">
                <li
                  class="page-item"
                  v-if="page > 1"
                  @click="fetchNew(page - 1)"
                >
                  <a class="page-link" tabindex="-1">Previous</a>
                </li>
                <li class="page-item" v-for="p in pages" :key="p">
                  <a
                    class="page-link"
                    :class="{ activePage: p == page }"
                    @click="fetchNew(p)"
                    >{{ p }}</a
                  >
                </li>
                <li
                  class="page-item"
                  v-if="page < numberOfPages"
                  @click="fetchNew(page + 1)"
                >
                  <a class="page-link">Next </a>
                </li>
                <li class="page-item">
                  <a class="page-link"> total record : {{ total }}</a>
                </li>
              </ul>
            </nav>
        <!-- /row -->
        <div class="contact_info">
              <div class="container">
                <ul class="clearfix">
                  <li>
                    <i class="pe-7s-map-marker"></i>
                    <h4>Address</h4>
                    <span
                      >31 International Airport Road Ikeja <br />Lagos -
                      Nigeria.</span
                    >
                  </li>
                  <li>
                    <i class="pe-7s-mail-open-file"></i>
                    <h4>Email address</h4>
                    <span
                      >support@faslearn.com<br /><small
                        >Monday to Friday 9am - 7pm</small
                      ></span
                    >
                  </li>
                  <li>
                    <i class="pe-7s-phone"></i>
                    <h4>Contacts info</h4>
                    <span
                      >+2349039884741<br /><small
                        >Monday to Friday 9am - 7pm</small
                      ></span
                    >
                  </li>
                </ul>
              </div>
            </div>
      </div>

      <div class="bee-row bee-row-7">
        <div class="bee-row-content">
          <div class="bee-col bee-col-1 bee-col-w12">
            <div class="bee-block bee-block-1 bee-spacer">
              <div class="spacer" style="height: 35px"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container margin_60_35">
        <div class="row mb-5 text-center justify-content-center">
          <h1>Our Partners</h1>
        </div>
        <div class="row">
          <div class="test-u owl-theme">
            <div class="test-scroll scroll">
              <div class="d-flex flex-row">
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994004/tpmunqguhgvrzo7spsrb.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994005/ltjfwztodxrneqb4nw96.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994005/t7pexnd9a7urnffsro0q.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994005/vueq2wtcum9qp07b6afl.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994004/tpmunqguhgvrzo7spsrb.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994005/ltjfwztodxrneqb4nw96.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994005/t7pexnd9a7urnffsro0q.jpg'"
                  />
                </div>
                <div class="entity">
                  <img
                    class="img-thumbnail img-fluid logo"
                    :src="'https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690994005/vueq2wtcum9qp07b6afl.jpg'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <PreviewModal
        :fileName="prevewfileName"
        :fileType="prevewfileType"
        v-if="prevewfileName != '' && prevewfileType != ''"
      />
      <Footer />
    </div>
  </div>
</template>

<script>
import Nav from "@/components/site/Nav.vue";
import Title from "@/components/site/GirlChildTitle.vue";
import Footer from "@/components/site/Footer.vue";
import Errors from "@/components/Errors.vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import PreviewModal from "@/components/PreviewModal.vue";
import RatingsDetails from "@/components/site/RatingsDetails.vue";
import Discount from "@/components/Discount";
import CourseImage from "@/components/CourseImage";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Nav,
    Title,
    Footer,
    Errors,
    SkeletonLoader,
    PreviewModal,
    Discount,
    CourseImage,
    RatingsDetails,
  },
  data() {
    return {
      details: "",
      rate: "",
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      reference: "",
      totalStudents: 0,
      price: 0,
      single_price: 0,
      course: "",
      coursePreview: "",
      prevewfileName: "",
      lectures: [],
      errors: [],
      whatYouLearn: "",
      initLoading: false,

      posts: "",
      published: "all",
      limit: 10,
      Loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      reachedFinalUserLimit: false,
      pages: [],
      numberOfPages: 0,
    };
  },

  computed: {
    ...mapGetters(["backEnd"]),
    ...mapGetters(["publicFiles"]),
  },
  methods: {
    ...mapActions([
      "fetchDigitalLiteracyCourse__public",
      "fetch_public_course_details__public",
      "fetchBlogPosts__public",
    ]),
    submitDonation() {
      if (!this.validateData()) {
        return;
      }
      const object = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        reference: this.reference,
        price: this.price,
        totalStudents: this.totalStudents,
        course: this.course,
      };
      //localStorage.setItem("fmrDonationLastReference", "T345750593907476");
      localStorage.setItem("fmrDonation", JSON.stringify(object));
      let str = window.location.href + "";
      const myArr = str.split("/fast-he");
      window.location.href = myArr[0] + "/fast-her/buy.html";
    },
    validateData() {
      let flag = true;
      let error = [];
      //this.course ="63da6d1bd188ab23d0905327"

      if (!this.email) {
        flag = false;
        error.push("email is required");
      }

      if (!this.firstName) {
        flag = false;
        error.push("first name is required");
      }

      if (!this.lastName) {
        flag = false;
        error.push("last name is required");
      }

      if (!this.phone) {
        flag = false;
        error.push("phone is required");
      }

      if (!this.totalStudents) {
        flag = false;
        error.push("total students is required");
      }

      if (!this.price) {
        flag = false;
        error.push("price is required");
      }

      if (!this.course) {
        flag = false;
        error.push("course is required");
      }

      if (flag == true) {
        this.errors = [];
        return true;
      } else {
        this.errors = error;
        return false;
      }
    },
    regexLink(link) {
      var expression = /https:\/\/[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/;
      var regex = new RegExp(expression);
      if (link != "") {
        if (link.match(regex)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    sanitizeDescription(text) {
      const words = text.split(" ");
      let backEndLink = "/Dcoursedetail/" + this.details._id;
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (this.regexLink(word)) {
          words[i] =
            '<a href="' +
            backEndLink +
            '" target="_blank" rel="noopener noreferrer">Click here to access link</a>';
        }
      }
      var newDescription = "";
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        newDescription = newDescription + " " + word;
      }
      return newDescription;
    },
    checkCoursePreview() {
      for (let i = 0; i < this.lectures.length; i++) {
        const element = this.lectures[i];
        if (element.sectionTitle == "Preview") {
          for (let j = 0; j < element.videos.length; j++) {
            const elementj = element.videos[j];
            if (elementj.isPreview) {
              this.coursePreview = elementj;
              this.prevewfileType = this.coursePreview.fileType;
              if (this.coursePreview.fileType != "embedLink") {
                let fileName = this.coursePreview.uri + "";
                this.prevewfileName = fileName;
              } else {
                this.prevewfileName = this.coursePreview.embedLink + "";
              }
              break;
            }
          }
          if (this.prevewfileName == "" && element.videos.length != 0) {
            this.coursePreview = element.videos[0];
            if (this.coursePreview.fileType != "application/pdf") {
              this.prevewfileType = this.coursePreview.fileType;
              if (this.coursePreview.fileType != "embedLink") {
                this.prevewfileName = this.coursePreview.uri + "";
              } else {
                this.prevewfileName = this.coursePreview.embedLink + "";
              }
            }
          }
        }
      }
    },
    scrollToDonate() {
      document.getElementById("donateNow").scrollIntoView();
    },
    getPages() {
      this.pages = [];
      this.numberOfPages = ~~(this.total / this.limit);
      if (this.total / this.limit > this.numberOfPages) {
        this.numberOfPages = this.numberOfPages + 1;
      }
      for (let i = 1; i <= this.numberOfPages; i++) {
        this.pages.push(i);
      }
    },
    fetchNew(page) {
      this.page = page;
      this.Loading = true;
      this.fetchBlogPosts__Admin({
        published: this.published,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.posts = res.data.blogPosts;
        this.total = res.data.total;
        this.getPages();
        this.Loading = false;
        window.scrollTo(0, 0);
      });
    },
  },
  mounted() {
    this.initLoading = true;
    this.fetchDigitalLiteracyCourse__public().then((res) => {
      this.details = res.data;
      if (this.details) {
        let what = this.details.whatYouLearn + "";
        this.whatYouLearn = what.split(",");
        this.single_price = this.details.price.$numberDecimal;
        console.log(this.details.price.$numberDecimal);
        console.log(this.details);
        this.course = this.details._id;
        let object = {
          courseId: this.course,
        };
        this.fetch_public_course_details__public(object).then((res) => {
          this.initLoading = false;
          this.lectures = res.data.modules;
          this.checkCoursePreview();
        });
      }
    });
    this.Loading = true;
    this.fetchBlogPosts__public({ page: this.page, limit: this.limit }).then(
      (res) => {
        this.posts = res.data.blogPosts;
        this.total = res.data.total;
        this.getPages();
        this.Loading = false;
      }
    );
  },
};
</script>

<style scoped>
@import url("./assets/css/blog.css");
@import url("./assets/css/custom.css");
@import url("./asset/css/bootstrap.min.css");
@import url("./asset/vendors/animation/animate.css");
@import url("./asset/css/style.css");
@import url("./asset/css/responsive.css");
@import url("./assets/css/style.css");
@import url("./assets/assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/css/vendors.css");
@import url("./assets/css/wizard.css");
@import url("./assets/css/icon_fonts/css/all_icons.min.css");
.featured_courses .item {
  width: 350px;
  display: inline-block;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 20px;
}

.bee-row,
.bee-row-content {
  position: relative;
}

.bee-page-container,
.bee-row-1,
.bee-row-10,
.bee-row-11,
.bee-row-12,
.bee-row-13,
.bee-row-14,
.bee-row-15,
.bee-row-16,
.bee-row-17,
.bee-row-18,
.bee-row-19,
.bee-row-20,
.bee-row-21,
.bee-row-5,
.bee-row-6,
.bee-row-7,
.bee-row-8,
body {
  background-color: #f7f9ff;
  color: #000;
  font-family: Nunito, Arial, Helvetica Neue, Helvetica, sans-serif;
}

.bee-row-11 .bee-col-1 .bee-block-3 a,
.bee-row-12 .bee-col-1 .bee-block-2 a,
.bee-row-13 .bee-col-1 .bee-block-3 a,
.bee-row-13 .bee-col-2 .bee-block-3 a,
.bee-row-13 .bee-col-3 .bee-block-3 a,
.bee-row-16 .bee-col-1 .bee-block-3 a,
.bee-row-16 .bee-col-2 .bee-block-5 a,
.bee-row-16 .bee-col-3 .bee-block-5 a,
.bee-row-16 .bee-col-4 .bee-block-5 a,
.bee-row-19 .bee-col-1 .bee-block-3 a,
.bee-row-19 .bee-col-2 .bee-block-2 a,
.bee-row-19 .bee-col-2 .bee-block-3 a,
.bee-row-19 .bee-col-2 .bee-block-4 a,
.bee-row-19 .bee-col-2 .bee-block-5 a,
.bee-row-19 .bee-col-3 .bee-block-2 a,
.bee-row-19 .bee-col-3 .bee-block-3 a,
.bee-row-19 .bee-col-4 .bee-block-2 a,
.bee-row-19 .bee-col-4 .bee-block-3 a,
.bee-row-19 .bee-col-4 .bee-block-4 a,
.bee-row-4 .bee-col-1 .bee-block-4 a,
.bee-row-6 .bee-col-2 .bee-block-3 a,
.bee-row-8 .bee-col-1 .bee-block-2 a,
a {
  color: #e07f4f;
}

* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

.bee-row-content {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}

.bee-row-content.reverse,
.bee-row-content.reverse .bee-col {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.bee-row-content .bee-col-w2 {
  flex-basis: 17%;
}

.bee-row-content .bee-col-w3 {
  flex-basis: 25%;
}

.bee-row-content .bee-col-w4 {
  flex-basis: 33%;
}

.bee-row-content .bee-col-w5 {
  flex-basis: 42%;
}

.bee-row-content .bee-col-w6 {
  flex-basis: 50%;
}

.bee-row-content .bee-col-w7 {
  flex-basis: 58%;
}

.bee-row-content .bee-col-w12 {
  flex-basis: 100%;
}

.bee-button .content {
  text-align: center;
}

.bee-button a,
.bee-icon .bee-icon-label-right a,
.bee-menu ul li a {
  text-decoration: none;
}

.bee-image {
  overflow: auto;
}

.bee-image .bee-center {
  margin: 0 auto;
}

.bee-row-16 .bee-col-2 .bee-block-1,
.bee-row-16 .bee-col-3 .bee-block-1,
.bee-row-16 .bee-col-4 .bee-block-1,
.bee-row-4 .bee-col-2 .bee-block-1,
.bee-row-9 .bee-col-1 .bee-block-2,
.bee-row-9 .bee-col-2 .bee-block-2,
.bee-row-9 .bee-col-3 .bee-block-2,
.bee-row-9 .bee-col-4 .bee-block-2 {
  width: 100%;
}

.bee-list ul,
.bee-menu ul {
  margin: 0;
  padding: 0;
}

.bee-icon {
  display: inline-block;
  vertical-align: middle;
}

.bee-icon .bee-content {
  display: flex;
  align-items: center;
}

.bee-image img {
  display: block;
  width: 100%;
}

.bee-paragraph {
  overflow-wrap: anywhere;
}

.bee-row-1 .bee-row-content,
.bee-row-11 .bee-row-content,
.bee-row-15 .bee-row-content,
.bee-row-17 .bee-row-content,
.bee-row-2 .bee-row-content,
.bee-row-3 .bee-row-content,
.bee-row-4 .bee-row-content,
.bee-row-5 .bee-row-content,
.bee-row-6 .bee-row-content {
  background-color: #fff;
  background-repeat: no-repeat;
  color: #000;
}

.bee-row-10 .bee-row-content,
.bee-row-12 .bee-row-content,
.bee-row-13 .bee-row-content,
.bee-row-14 .bee-row-content,
.bee-row-7 .bee-row-content,
.bee-row-8 .bee-row-content,
.bee-row-9 .bee-row-content {
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  color: #000;
}

.bee-row-1 .bee-col-1,
.bee-row-10 .bee-col-1,
.bee-row-11 .bee-col-1,
.bee-row-11 .bee-col-2,
.bee-row-13 .bee-col-1,
.bee-row-13 .bee-col-2,
.bee-row-13 .bee-col-3,
.bee-row-14 .bee-col-1,
.bee-row-15 .bee-col-1,
.bee-row-16 .bee-col-2,
.bee-row-16 .bee-col-3,
.bee-row-16 .bee-col-4,
.bee-row-17 .bee-col-1,
.bee-row-18 .bee-col-1,
.bee-row-19 .bee-col-2,
.bee-row-19 .bee-col-3,
.bee-row-19 .bee-col-4,
.bee-row-2 .bee-col-3,
.bee-row-20 .bee-col-1,
.bee-row-21 .bee-col-1,
.bee-row-3 .bee-col-1,
.bee-row-4 .bee-col-2,
.bee-row-5 .bee-col-1,
.bee-row-6 .bee-col-1,
.bee-row-6 .bee-col-2,
.bee-row-7 .bee-col-1,
.bee-row-9 .bee-col-1,
.bee-row-9 .bee-col-2,
.bee-row-9 .bee-col-3,
.bee-row-9 .bee-col-4 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.bee-row-2,
.bee-row-3,
.bee-row-4 {
  background-color: #f7f9ff;
  background-repeat: no-repeat;
}

.bee-row-16 .bee-col-1,
.bee-row-2 .bee-col-1 {
  padding: 5px 25px;
}

.bee-row-2 .bee-col-1 .bee-block-1 {
  padding: 10px 5px;
  width: 100%;
}

.bee-row-2 .bee-col-2 {
  padding: 5px 15px;
}

.bee-row-2 .bee-col-2 .bee-block-1 {
  color: #000;
  font-family: inherit;
  font-size: 17px;
  text-align: center;
}

.bee-row-2 .bee-col-3 .bee-block-1 {
  padding: 5px 10px;
  text-align: center;
}

.bee-row-19 .bee-col-1,
.bee-row-4 .bee-col-1 {
  padding: 5px 25px 5px 20px;
}

.bee-row-11 .bee-col-1 .bee-block-2,
.bee-row-16 .bee-col-2 .bee-block-2,
.bee-row-16 .bee-col-3 .bee-block-2,
.bee-row-16 .bee-col-4 .bee-block-2,
.bee-row-4 .bee-col-1 .bee-block-2,
.bee-row-6 .bee-col-2 .bee-block-2,
.bee-row-9 .bee-col-1 .bee-block-3,
.bee-row-9 .bee-col-2 .bee-block-3,
.bee-row-9 .bee-col-3 .bee-block-3,
.bee-row-9 .bee-col-4 .bee-block-3 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  text-align: center;
  width: 100%;
}

.bee-row-4 .bee-col-1 .bee-block-3 {
  padding-top: 10px;
  text-align: center;
  width: 100%;
}

.bee-row-4 .bee-col-1 .bee-block-4 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.bee-row-4 .bee-col-1 .bee-block-5 {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: left;
}

.bee-row-4 .bee-col-1 .bee-block-6 {
  color: #000;
  font-family: inherit;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}

.bee-row-11 .bee-col-2 .bee-block-1,
.bee-row-6 .bee-col-1 .bee-block-1 {
  padding-top: 25px;
  width: 100%;
}

.bee-row-6 .bee-col-2 .bee-block-1 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  text-align: center;
  width: 100%;
}

.bee-row-11 .bee-col-1 .bee-block-3,
.bee-row-13 .bee-col-1 .bee-block-3,
.bee-row-13 .bee-col-2 .bee-block-3,
.bee-row-13 .bee-col-3 .bee-block-3,
.bee-row-16 .bee-col-1 .bee-block-3,
.bee-row-2 .bee-col-2 .bee-block-1 li,
.bee-row-6 .bee-col-2 .bee-block-3,
.bee-row-8 .bee-col-1 .bee-block-2 {
  padding: 10px 20px;
}

.bee-row-11 .bee-col-1 .bee-block-4,
.bee-row-11 .bee-col-1 .bee-block-5,
.bee-row-11 .bee-col-1 .bee-block-6,
.bee-row-6 .bee-col-2 .bee-block-4,
.bee-row-6 .bee-col-2 .bee-block-5,
.bee-row-6 .bee-col-2 .bee-block-6 {
  padding: 10px 15px;
}

.bee-row-11 .bee-col-1 .bee-block-7,
.bee-row-6 .bee-col-2 .bee-block-7 {
  padding: 10px 20px 35px;
  text-align: left;
}

.bee-row-4 .bee-col-1 .bee-block-6 .bee-icon-image,
.bee-row-8 .bee-col-1 {
  padding: 5px;
}

.bee-row-12 .bee-col-1 .bee-block-1,
.bee-row-16 .bee-col-1 .bee-block-2,
.bee-row-8 .bee-col-1 .bee-block-1 {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  width: 100%;
}

.bee-row-9 .bee-col-1 .bee-block-1,
.bee-row-9 .bee-col-2 .bee-block-1,
.bee-row-9 .bee-col-3 .bee-block-1,
.bee-row-9 .bee-col-4 .bee-block-1 {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.bee-row-11 .bee-col-1 .bee-block-1 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 35px;
  text-align: center;
  width: 100%;
}

.bee-row-12 .bee-col-1 {
  padding-bottom: 5px;
  padding-top: 35px;
}

.bee-row-12 .bee-col-1 .bee-block-2,
.bee-row-16 .bee-col-2 .bee-block-5,
.bee-row-16 .bee-col-3 .bee-block-5,
.bee-row-16 .bee-col-4 .bee-block-5 {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.bee-row-13 .bee-col-1 .bee-block-1,
.bee-row-13 .bee-col-2 .bee-block-1,
.bee-row-13 .bee-col-3 .bee-block-1 {
  padding: 10px 40px 20px;
  text-align: center;
  width: 100%;
}

.bee-row-13 .bee-col-1 .bee-block-2,
.bee-row-13 .bee-col-2 .bee-block-2,
.bee-row-13 .bee-col-3 .bee-block-2 {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.bee-row-13 .bee-col-1 .bee-block-4,
.bee-row-13 .bee-col-2 .bee-block-4,
.bee-row-13 .bee-col-3 .bee-block-4 {
  padding: 10px 20px;
  text-align: center;
}

.bee-row-16 .bee-row-content {
  background-color: #fff;
  background-image: url("https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690995766/spn682hn11mvpxujly5i.jpg");
  background-repeat: no-repeat;
  color: #000;
}

.bee-row-16 .bee-col-2 .bee-block-3,
.bee-row-16 .bee-col-3 .bee-block-3,
.bee-row-16 .bee-col-4 .bee-block-3 {
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
}

.bee-row-16 .bee-col-2 .bee-block-4,
.bee-row-16 .bee-col-3 .bee-block-4,
.bee-row-16 .bee-col-4 .bee-block-4 {
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.bee-row-18 .bee-row-content,
.bee-row-19 .bee-row-content,
.bee-row-20 .bee-row-content {
  background-color: #ef6421;
  background-repeat: no-repeat;
  color: #000;
}

.bee-row-19 .bee-col-1 .bee-block-1 {
  padding-bottom: 15px;
  padding-right: 10px;
  padding-top: 5px;
  width: 100%;
}

.bee-row-19 .bee-col-1 .bee-block-2 {
  padding-left: 5px;
  text-align: center;
  width: 100%;
}

.bee-row-19 .bee-col-1 .bee-block-3 {
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 10px;
}

.bee-row-19 .bee-col-2 .bee-block-1,
.bee-row-19 .bee-col-3 .bee-block-1,
.bee-row-19 .bee-col-4 .bee-block-1 {
  padding-left: 20px;
  text-align: center;
  width: 100%;
}

.bee-row-19 .bee-col-2 .bee-block-2,
.bee-row-19 .bee-col-2 .bee-block-3,
.bee-row-19 .bee-col-2 .bee-block-4,
.bee-row-19 .bee-col-4 .bee-block-2,
.bee-row-19 .bee-col-4 .bee-block-3,
.bee-row-19 .bee-col-4 .bee-block-4 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.bee-row-19 .bee-col-2 .bee-block-5,
.bee-row-19 .bee-col-3 .bee-block-2,
.bee-row-19 .bee-col-3 .bee-block-3 {
  padding: 10px 20px 5px;
}

.bee-row-19 .bee-col-3 .bee-block-4 {
  padding: 10px;
  text-align: left;
}

.bee-row-21 .bee-row-content {
  background-repeat: no-repeat;
  color: #000;
}

.bee-row-21 .bee-col-1 .bee-block-1 {
  color: #9d9d9d;
  font-family: inherit;
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

.bee-row-21 .bee-col-1 .bee-block-1 .bee-icon:not(.bee-icon-first) .bee-content,
.bee-row-4 .bee-col-1 .bee-block-6 .bee-icon:not(.bee-icon-first) .bee-content {
  margin-left: 0;
}

.bee-row-4 .bee-col-1 .bee-block-6 .bee-icon::not(.bee-icon-last) .bee-content {
  margin-right: 0;
}

.bee-row-11 .bee-col-1 .bee-block-3,
.bee-row-16 .bee-col-1 .bee-block-3,
.bee-row-4 .bee-col-1 .bee-block-4,
.bee-row-6 .bee-col-2 .bee-block-3 {
  color: #5f6070;
  direction: ltr;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 150%;
  text-align: left;
}

.bee-row-11 .bee-col-1 .bee-block-3 p:not(:last-child),
.bee-row-12 .bee-col-1 .bee-block-2 p:not(:last-child),
.bee-row-13 .bee-col-1 .bee-block-3 p:not(:last-child),
.bee-row-13 .bee-col-2 .bee-block-3 p:not(:last-child),
.bee-row-13 .bee-col-3 .bee-block-3 p:not(:last-child),
.bee-row-16 .bee-col-1 .bee-block-3 p:not(:last-child),
.bee-row-16 .bee-col-2 .bee-block-5 p:not(:last-child),
.bee-row-16 .bee-col-3 .bee-block-5 p:not(:last-child),
.bee-row-16 .bee-col-4 .bee-block-5 p:not(:last-child),
.bee-row-19 .bee-col-1 .bee-block-3 p:not(:last-child),
.bee-row-19 .bee-col-2 .bee-block-2 p:not(:last-child),
.bee-row-19 .bee-col-2 .bee-block-3 p:not(:last-child),
.bee-row-19 .bee-col-2 .bee-block-4 p:not(:last-child),
.bee-row-19 .bee-col-2 .bee-block-5 p:not(:last-child),
.bee-row-19 .bee-col-3 .bee-block-2 p:not(:last-child),
.bee-row-19 .bee-col-3 .bee-block-3 p:not(:last-child),
.bee-row-19 .bee-col-4 .bee-block-2 p:not(:last-child),
.bee-row-19 .bee-col-4 .bee-block-3 p:not(:last-child),
.bee-row-19 .bee-col-4 .bee-block-4 p:not(:last-child),
.bee-row-4 .bee-col-1 .bee-block-4 p:not(:last-child),
.bee-row-6 .bee-col-2 .bee-block-3 p:not(:last-child),
.bee-row-8 .bee-col-1 .bee-block-2 p:not(:last-child) {
  margin-bottom: 16px;
}

.bee-row-2 .bee-col-2 .bee-block-1 li a {
  color: #393d47;
}

@media (max-width: 768px) {
  .bee-row-content:not(.no_stack) {
    display: block;
  }
}

.bee-row-11 .bee-col-1 .bee-block-4,
.bee-row-11 .bee-col-1 .bee-block-5,
.bee-row-11 .bee-col-1 .bee-block-6,
.bee-row-6 .bee-col-2 .bee-block-4,
.bee-row-6 .bee-col-2 .bee-block-5,
.bee-row-6 .bee-col-2 .bee-block-6 {
  color: #5f6070;
  direction: ltr;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 120%;
  text-align: left;
}

.bee-row-11 .bee-col-1 .bee-block-4 ul,
.bee-row-11 .bee-col-1 .bee-block-5 ul,
.bee-row-11 .bee-col-1 .bee-block-6 ul,
.bee-row-6 .bee-col-2 .bee-block-4 ul,
.bee-row-6 .bee-col-2 .bee-block-5 ul,
.bee-row-6 .bee-col-2 .bee-block-6 ul {
  list-style-position: outside;
}

.bee-row-11 .bee-col-1 .bee-block-4 li:not(:last-child),
.bee-row-11 .bee-col-1 .bee-block-5 li:not(:last-child),
.bee-row-11 .bee-col-1 .bee-block-6 li:not(:last-child),
.bee-row-6 .bee-col-2 .bee-block-4 li:not(:last-child),
.bee-row-6 .bee-col-2 .bee-block-5 li:not(:last-child),
.bee-row-6 .bee-col-2 .bee-block-6 li:not(:last-child) {
  margin-bottom: 0;
}

.bee-row-11 .bee-col-1 .bee-block-4 li ul,
.bee-row-11 .bee-col-1 .bee-block-5 li ul,
.bee-row-11 .bee-col-1 .bee-block-6 li ul,
.bee-row-6 .bee-col-2 .bee-block-4 li ul,
.bee-row-6 .bee-col-2 .bee-block-5 li ul,
.bee-row-6 .bee-col-2 .bee-block-6 li ul {
  margin-top: 0;
}

.bee-row-11 .bee-col-1 .bee-block-4 li a,
.bee-row-11 .bee-col-1 .bee-block-5 li a,
.bee-row-11 .bee-col-1 .bee-block-6 li a,
.bee-row-6 .bee-col-2 .bee-block-4 li a,
.bee-row-6 .bee-col-2 .bee-block-5 li a,
.bee-row-6 .bee-col-2 .bee-block-6 li a {
  color: #8a3b8f;
}

.bee-row-11 .bee-col-1 .bee-block-4 li li,
.bee-row-11 .bee-col-1 .bee-block-5 li li,
.bee-row-11 .bee-col-1 .bee-block-6 li li,
.bee-row-6 .bee-col-2 .bee-block-4 li li,
.bee-row-6 .bee-col-2 .bee-block-5 li li,
.bee-row-6 .bee-col-2 .bee-block-6 li li {
  margin-left: 40px;
}

.bee-row-13 .bee-col-1 .bee-block-3,
.bee-row-13 .bee-col-2 .bee-block-3,
.bee-row-13 .bee-col-3 .bee-block-3,
.bee-row-8 .bee-col-1 .bee-block-2 {
  color: #5f6070;
  direction: ltr;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 150%;
  text-align: center;
}

.bee-row-12 .bee-col-1 .bee-block-2 {
  color: #5f6070;
  direction: ltr;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 150%;
  text-align: center;
}

.bee-row-16 .bee-col-2 .bee-block-5,
.bee-row-16 .bee-col-3 .bee-block-5,
.bee-row-16 .bee-col-4 .bee-block-5 {
  color: #5f6070;
  direction: ltr;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 150%;
  text-align: center;
}

.bee-row-19 .bee-col-1 .bee-block-3,
.bee-row-19 .bee-col-2 .bee-block-2,
.bee-row-19 .bee-col-2 .bee-block-3,
.bee-row-19 .bee-col-2 .bee-block-4,
.bee-row-19 .bee-col-2 .bee-block-5,
.bee-row-19 .bee-col-3 .bee-block-2,
.bee-row-19 .bee-col-3 .bee-block-3,
.bee-row-19 .bee-col-4 .bee-block-2,
.bee-row-19 .bee-col-4 .bee-block-3,
.bee-row-19 .bee-col-4 .bee-block-4 {
  color: #fff;
  direction: ltr;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 180%;
  text-align: left;
}

.bee-row-21 .bee-col-1 .bee-block-1 .bee-icon-image {
  padding: 5px 6px 5px 5px;
}

.bee-row-21
  .bee-col-1
  .bee-block-1
  .bee-icon::not(.bee-icon-last)
  .bee-content {
  margin-right: 0;
}

.myButton {
  cursor: pointer;
  font-size: 16px;
  background-color: #e07f4f;
  border-bottom: 0px solid transparent;
  border-left: 0px solid transparent;
  border-radius: 50px;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  color: #ffffff;
  font-family: "Nunito", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  max-width: 100%;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  width: auto;
  direction: ltr;
  display: inline-block;
}

.spaces {
  padding-top: 50px;
  padding-bottom: 50px;
}

.donateForm {
  padding: 30px;
}

/* https://codepen.io/DuskoStamenic/pen/ZEvBKdw */

@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap";
:root {
  --color: rgb(63 63 63);
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
}

.parallax-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*  this is where the magic happens:  */
  background-attachment: fixed;
  background-image: url(https://res.cloudinary.com/dwrxwkkqh/image/upload/v1690995766/spn682hn11mvpxujly5i.jpg);
  background-position: center;
  background-size: cover;
  color: var(--color);
  row-gap: 4rem;
  text-align: center;
}

.buffer {
  width: 100%;
  min-height: 60vh;
  background-color: transparent;
  opacity: 0.5;
}

.parallax-container2 {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6rem 0;
  background-image: url(https://res.cloudinary.com/dwrxwkkqh/image/upload/v1697042976/jzj3c2nbvj7m1vpepopp.jpg);
  gap: 1.6em;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

.parent {
  background: rgba(0, 0, 0, 0.8);
  /* border-radius: 10px; */
  padding: 20px;
}

p {
  max-width: 52ch;
  padding: 1em;
  line-height: 1.5;
}

a {
  display: inline-block;
  padding: 1em 3.5em;
  border-radius: 0.3em;
  background-color: var(--color);
  color: white;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
}

h3 {
  margin-top: 1em;
  font-size: 1.6rem;
  font-weight: 700;
}

.biggie {
  height: 300px;
  width: 100%;
  overflow-y: hidden;
  overflow: hidden;
  margin-bottom: 20px;
}

.scroll {
  height: 100%;
  width: 3100px;
  animation-name: slideTeacher;
  /* animation-delay: 5s; */
  animation-duration: 40s;
  animation-iteration-count: infinite;
}

.logo{
  height: 250px;
  width: 250px;
  object-fit: contain;
}

.teachers {
  display: inline-block;
  height: 100%;
  width: 200px;
  margin-left: 50px;
}

.entity {
  padding: auto;
  margin: 20px;
}

@keyframes slideTeacher {
  0% {
    margin-left: 0px;
  }
  15% {
    margin-left: -300px;
  }
  30% {
    margin-left: -600px;
  }
  45% {
    margin-left: -900px;
  }
  60% {
    margin-left: -1200px;
  }
  100% {
    margin-left: 0px;
  }
}
</style>