<template>
    <div>
        <Nav />

        <Tittle :type="'general'">
            <h1 class="fadeInUp"><span></span>About Fast-Her</h1>
        </Tittle>

        <div >
			<div class="container">
                <br><br>
                <h2> Welcome to Fast-Her </h2>
                <br>
                <h4>Empowering Women through Digital Literacy Online Courses by FasLearn Africa</h4>
                Fast-Her is an innovative initiative that aims to equip women with the essential digital skills 
                necessary to thrive in today's technology-driven world. As an online course specifically designed for women, 
                Fast-Her addresses the challenges women may face in accessing digital resources, 
                overcoming social or cultural barriers, and building confidence in learning new digital skills.

                <br><br><br>
                <h4>The Importance of Fast-Her </h4>
                In the contemporary landscape, digital literacy is vital for personal and professional success. Unfortunately,
                 systemic barriers such as limited technology access, lower digital education rates, 
                 and societal norms hindering women's participation in tech careers often leave women behind. 
                 Fast-Her aims to bridge this gender gap by empowering women with the digital skills and confidence 
                 required to succeed in the digital age.

                <br><br><br>
                <h4>Fast-Her and the Sustainable Development Goals (SDGs)</h4>
                Fast-Her is aligned with the United Nations' Sustainable Development Goals,
                 with a particular focus on Goal 5: Gender Equality. By offering access to digital education, 
			</div>
		</div>
		<!--/contact_info-->
		<br><br>
        <Footer />
    </div>
</template>

<script>
    import Nav from '@/components/site/Nav.vue'
    import Tittle from '@/components/site/Tittle.vue'
    import Footer from '@/components/site/Footer.vue'
    export default {
        name: 'Contact',
        components: {
            Nav,
            Tittle,
            Footer
        },
        mounted(){
            let js = document.createElement('script')
            js.setAttribute('src', 'js/main.js')
            document.head.appendChild(js)
            let jsc = document.createElement('script')
            jsc.setAttribute('src', 'js/common_scripts.js')
            document.head.appendChild(jsc)
        }
    }
</script>

<style>
</style>